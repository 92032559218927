<template>
  <Modal v-model="visible" :title="'修改考试列表'" width="484" class="paper-modal" @on-cancel="close" footer-hide :mask-closable="false">
    <Form ref="paperBankForm" :model="paperBankForm" :rules="ruleValidate" :label-width="80">
      <FormItem label="考试名称" prop="name">
        <Input v-model="paperBankForm.name" placeholder="请输入考试名称(30字以内)" />
      </FormItem>
      <FormItem label="考试简介" prop="description">
        <Input v-model="paperBankForm.description" placeholder="请输入考试简介(30字以内)" />
      </FormItem>
      <FormItem label="考试时长" prop="limitTime">
        <InputNumber v-model.number="paperBankForm.limitTime" placeholder="请输入考试时长(分钟)" :min="1" :max="999" style="width: 100%" />
        <!--        <div v-if="!ruleValidate.limitTime.error" class="form-tip">考试时长最低为1分钟</div>-->
      </FormItem>
      <FormItem label="通过比例" prop="passRate">
        <InputNumber v-model.trim="paperBankForm.passRate" placeholder="请设置通过比例" :min="0" :max="1" :step="0.1" style="width: 100%" />
        <!--        <div v-if="!ruleValidate.passRate.error" class="form-tip">通过分数最高为1分</div>-->
      </FormItem>
    </Form>
    <div class="exam-footer">
      <el-button size="mini" @click="close">取消</el-button>
      <el-button size="mini" @click="dealQuestionBank()" type="primary" :loading="loading">确定</el-button>
    </div>
  </Modal>
</template>
<script>
import postExam from '../../../../api/postExam'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    examId: {
      type: Number
    },
    paperBank: {
      type: Object
    }
  },
  data: function() {
    const that = this

    return {
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      percentage: 0,
      uploadUrl: '',
      uploadSuccess: false,
      isUploading: false,
      visible: this.value,
      loading: false,
      paperBankForm: {
        name: this.paperBank.name,
        description: this.paperBank.description,
        limitTime: Number(this.paperBank.limitTime),
        passRate: 0.6
      },
      ruleValidate: {
        name: [
          { required: true, message: '请设置考试名称', trigger: 'blur' },
          { max: 30, message: '考试名名称不得超过30个字', trigger: 'blur' }
        ],
        description: {
          max: 30,
          message: '考试名简介不得超过30个字',
          trigger: 'blur'
        },
        limitTime: {
          type: 'number',
          required: true,
          message: '考试时长不为空',
          trigger: 'blur'
        },
        passRate: {
          type: 'number',
          required: true,
          message: '请设置通过比例',
          trigger: 'blur'
        }
      }
    }
  },
  inject: ['reload'],
  methods: {
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.isUploading = false
      this.uploadSuccess = false
    },
    //判断是创建列表还是修改试列表
    dealQuestionBank() {
      this.loading = true
      this.$refs.paperBankForm.validate(val => {
        if (val) {
          let paperBankInfo = Object.assign(this.paperBankForm)
          //修改需要多添加一项id
          paperBankInfo.examId = this.paperBank.examId
          postExam.editExam(paperBankInfo).then(() => {
            this.close()
            this.$message.success('修改成功')
            this.$emit('on-success')
            this.reload()
          })
          // .finally(() => {
          //   this.loading = false
          //   this.isUploading = false
          //   this.uploadSuccess = false
          // })
        } else {
          this.loading = false
        }
      })
      // this.loading = false
    }
  },
  watch: {
    value(val) {
      this.visible = val
      this.paperBankForm = {
        name: this.paperBank.name,
        description: this.paperBank.description,
        limitTime: this.paperBank.limitTime,
        passRate: 0.6
      }
    }
  }
}
</script>
<style lang="less" scoped>
.paper-modal ::v-deep .ivu-modal {
  .ivu-modal-body {
    .custom-upload {
      .ivu-upload-drag {
        width: 124px;

        img {
          width: 124px;
          height: 90px;
        }
      }

      &.uploaded {
        .ivu-upload-drag {
          border: none;
          display: inline;

          &:hover {
            border: none;
          }
        }
      }
    }

    .upload {
      width: 124px;
      height: 90px;
      font-size: 60px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #b8c2d1;
      }
    }
  }
}
.exam-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
